.news-detail table,
table.ce-table {
  width: 100%;
  display: block; //Overflow horizontal scrollbar, wenn zu breit
  overflow-x: auto; //Overflow horizontal scrollbar, wenn zu breit

  @include media-breakpoint-up(xl) {
    display: table; //Tabelle ab 1200px 100% width
  }

  th,
  td {
    padding: 0.75rem 0.5rem;

    @include media-breakpoint-up(xl) {
      padding: 1.5rem 1.25rem;
    }
  }

  thead tr {
    background: $dlrgColorBlue;
    color: $mainColorWhite;
    font-size: 1.25rem;

    @include media-breakpoint-up(xl) {
      font-size: 1.6rem;
    }
  }

  tbody {
    tr {
      th:only-of-type {
        background: rgba($dlrgColorBlue, 0.8);
        color: $mainColorWhite;
      }

      &:nth-child(even) {
        background: $dlrgColorGreyEven;
      }

      &:nth-child(odd) {
        background: $dlrgColorGreyOdd;
      }
    }
  }
}

table {
  &:not(.ce-table, .dlrg-table) {
    // wenn im CKEditor die Tabelle mit der Maus vergrößert/verkleinert wird, stehen Werte für width/height im Code
    // dies erzeugt Anzeigeprobleme auf kleineren Geräten, daher weg damit
    width: unset;
    height: unset;
  }
}

.page {
  table,
  .table,
  .dlrg-table,
  .dlrg-bs-table {
    color: $mainColorGrey; //sonst vom Hintergrund nicht unterscheidbar

    a:not(.btn):not(.fc-daygrid-day-number):not(.fc-col-header-cell-cushion) { // Link-Styling für Button und Links in Kalender-Monat/Wochen/Tag ausschließen
      //Links in Tabellen wie Standard-Link darstellen
      color: $dlrgColorBlue;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dlrg-table,
  .dlrg-bs-table {
    &.table-striped {
      background-color: $mainColorWhite;
      border: 0;
    }

    .row-kopfzeile,
    .row-striped {
      // dass Abstand zum Rand vorhanden ist
      margin-left: 0;
      margin-right: 0;
    }

    thead,
    .row-kopfzeile {
      color: $mainColorWhite;
      background: $dlrgColorBlue;

      > div,
      th {
        padding: 1.5625rem .75rem;
        vertical-align: top;
        border: 0;
      }
    }

    tbody tr,
    .row-striped {
      padding: 0.75rem 0;
      vertical-align: top;
      border: 0;

      > div[class*="col"] {
        margin-bottom: 0.75rem;
      }

      &:nth-of-type(even) {
        background-color: $mainColorWhite;
      }

      &:nth-of-type(odd) {
        background-color: $mainColorLightGrey;
      }
    }

    .row-hover:hover {
      background-color: $headerColorLightGrey;
    }

    .show-full {
      display: none;
    }

    .screen-small {
      &::after {
        content: "\A";
        white-space: pre;
      }
    }
    
    .badge {
      white-space: unset;
    }

    $breakpointsDLRGBsTable: sm, md, lg, xl;

    @for $i from 1 through 4 {
      @include media-breakpoint-up(#{nth($breakpointsDLRGBsTable, $i)}) {
        .screen-#{nth($breakpointsDLRGBsTable, $i)} {
          &.show-full {
            display: unset;
          }

          &.screen-small {
            display: none;
          }

          &.row-striped {
            >div {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
