.page {
  form {
    legend {
      color: $dlrgColorBlue;
    }

    select,
    textarea,
    input {
      &.form-control {
        border: solid 1px $dlrgColorBlue;
        background-color: $mainColorWhite;

        &:required {
          border-left: $dlrgColorRequired solid 5px !important;
        }
      }
    }

    select,
    input {
      &.form-control {
        min-height: 50px;
      }
    }

    input[type="file"] {
      &.form-control {
        color: $headerColorGrey;
        background-color: $headerColorLightGrey;
        border: unset;
      }
    }

    .form-check {
      input {
        &[type="checkbox"],
        &[type="radio"] {
          width: 1em;
          height: 1em;
        }
      }
    }

    [class*="label"] {
      padding-top: 7.5px;
    }
  }
}