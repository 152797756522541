.page {
  .list-group-item {
    border: 0;
    padding: .75rem 4rem;
  }

  .list-group {
    li {
      counter-increment: li;

      &::before {
        content: counter(li);
        color: $mainColorWhite;
        display: flex;
        width: 40px;
        height: 40px;
        margin-left: -4rem;
        margin-top: -5px;
        background: $dlrgColorBlue;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        float: left;
      }
    }
  }
}