.page {
    .gallery {
        .image-small,
        .image-big {
            border: 10px solid $mainColorWhite;
            text-align: center;
            vertical-align: bottom;
            text-decoration: none;
            padding: 0;

            p {
                width: 100%;
                font-weight: normal;
                font-size: 19px;
                color: rgba(6,6,6,0.0);
                position: absolute;
                bottom: 0;
                padding: 0.5rem;
                margin: 0;
            }

            &:hover p {
                color: $mainColorWhite;
            }
        }

        .image-small {
            height: 275px;
        }

        .image-big {
            height: 550px;
        }

        .gallery-bgBlue {
            width: 100%;
            height: 100%;
            opacity: 0.3;
        }
    }
}

#gallerylist {
    .teaser{
        display: none;
        bottom: 0;

        @include touchdevice {
            // alle auf important, dass inline-Werte überschrieben werden
            // verhindert ungewollten JS-Hover-Effekt bei Touch-Geräten
            display: block !important;
            padding: 1rem !important;
        }
    }

    .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .bgBlue {
        .textWhite {
            h3 {
                span {
                    background-color: unset !important;
                    font-weight: bolder;
                    text-transform: uppercase;
                }
            }
        }
    }
}
