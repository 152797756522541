.page {
  .publication.container {
    .jumbotron {
      width: 80%; //give more room than 60%
      margin: 0 auto;
      text-align: center;

      .publicationelement {
        margin-bottom: 20px;

        // Quickfix, bis TYPO3 V10 ausgespielt wurde
        max-width: unset;
        .imagearea img {
          height: auto;
          max-width: 100%;
        }
        // ENDE Quickfix
      }

      .row {
        justify-content: center; //centers content horizontal
      }

      .imagearea {
        text-align: center;

        img {
          width: 170px;
          height: 225px;
          max-width: none; //dont force image into to small columns, allows for nice breaks after each element
        }
      }

      .contentarea {
        text-align: center;

        a {
          text-decoration: none;
          display: block;
        }

        img {
          height: 30px;
          width: 30px;
          margin: 5px 0;
        }
      }
    }
  }
}