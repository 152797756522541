a.btn,
a.btn-sm {
  text-decoration: none;
}

.btn-more {
  border: 1px solid $dlrgColorBlue;
  color: $dlrgColorBlue;
}

.btn-red,
.btn-blue {
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }

  &.disabled,
  &:disabled {
    color: $mainColorWhite;
    background-color: $dlrgColorDisabled;
    border-color: $dlrgColorDisabled;
  }
}

.page {
  .btn {
    font-size: 1.25rem;
    padding: .4rem 0.875rem;
    border-radius: .5rem;
    margin-bottom: 1rem;
    white-space: unset;

    &.btn-sm {
      font-size: 1.10rem;
    }

    &.btn-lg {
      font-size: 1.4rem;
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.125rem;

      &.btn-sm {
        font-size: 0.95rem;
      }

      &.btn-lg {
        font-size: 1.3rem;
      }
    }

    &.btnSpenden {
      padding: 0.625rem 0.675rem;

      img {
        padding-right: 1.875rem;
        display: none;
      }
    }

    @include media-breakpoint-up(md) {
      + .btn { //ab zweitem Button margin left
        margin-left: 1rem;
      }
    }
  }

  .btn-group {
    .btn {
      + .btn { // bei btn-group kein Abstand zwischen Button
        margin-left: 0;
      }
    }
  }

  #main-content,
  footer {
    .btn {
      &,
      &.btnSpenden {
        width: auto;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}

/* RedButton Start */
.btn-red {
  &,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &.dropdown-toggle {
    color: $dlrgColorYellow;
    background-color: $dlrgColorRed;
    border-color: $dlrgColorRed;
  }

  &:hover {
    color: $dlrgColorYellow;
    background-color: $dlrgColorRedHover;
    border-color: $dlrgColorRedHover;
  }
}
/* RedButton Ende */

/* YellowButton Start */
.btn-yellow {
  background-color: $dlrgColorYellow;
  border-color: $dlrgColorYellow;
}
/* YellowButton Ende */


/* BlueButton */
.btn-blue {
  &,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &.dropdown-toggle {
    color: $mainColorWhite;
    background-color: $dlrgColorBlue;
    border-color: $dlrgColorBlue;
  }

  &:hover {
    color: $mainColorWhite;
    background-color: $dlrgColorBlueHover;
    border-color: $dlrgColorBlueHover;
  }
}
/* BlueButton Ende */


/* Dropdown Start*/
.btn-select {
  border-radius: 0;
  box-shadow: none;
  background: $dlrgColorRed;
  color: $mainColorWhite;
  height: 52px;
  border: 1px solid $dlrgColorRed;
}

.dropdown-menu {
  border-radius: 0;
}

/* Dropdown Ende*/
