.img-cont {
  &.social-media {
    position: absolute;
    top: 15px;
    right: 0;
    width: 100%;
  }
}

.social-media {
  .fa,
  .fab {
    width: 3.25rem;
    height: 3.25rem;
    display: block;
    font-size: 2rem;
    float: left;
    margin-right: 0.3125rem;
    text-align: center;
    padding-top: 0.6rem;
    padding-left: 0.1rem;

    &.white {
      color: $dlrgColorBlue;
      background: $mainColorWhite;
    }

    &.blue {
      color: $mainColorWhite;
      background: $dlrgColorBlue;
    }
  }
}

/* Social Media Shariff Start */
.shariff {
  .fa {
    font-size: 24px !important;
  }

  .fab,
  .fas {
    font-size: 1.75rem !important;
  }

  .fas {
    margin-right: 2px;
  }

  .orientation-horizontal {
    li a,
    li.shariff-button,
    &.col-3 li {
      width: 50px !important;
      height: 50px !important;
      min-width: 50px !important;
      max-width: 50px !important;
    }

    li {
      .share_text {
        display: none !important;
      }

      a {
        padding-left: 0.6rem;
      }
    }
  }

  ul.col-3 {
    width: 100% !important;
  }

  .theme-grey {
    .shariff-button {
      a {
        background-color: #0069b4 !important;
      }
    }
  }
}