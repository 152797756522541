@mixin touchdevice {
  @media (hover:none) and (pointer:none), (hover:none) and (pointer:coarse) {
    @content;
  }
}

$desktopmenu-ausblenden: 550px;

@mixin mobilemenu {
  @media (max-height: $desktopmenu-ausblenden), (hover:none) and (pointer:none), (hover:none) and (pointer:coarse) {
    @content;
  }
}