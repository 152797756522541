.page {
  .carousel.slide {
    .carousel-control-prev,
    .carousel-control-next {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        height: 40px;
      }
    }
  }

  .carousel-indicators {
    @include media-breakpoint-up(lg) {
      left: auto !important;
      margin-left: auto !important;
      margin-right: 5% !important;
    }

    li {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #ededec;
      opacity: 1;

      &.active {
        background-color: $dlrgColorRed !important;
      }
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    top: 50%;
  }

  .carousel-fade {
    .carousel-item {
      opacity: 0;
      transition-duration: .6s;
      transition-property: opacity;
    }

    .carousel-item.active,
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      opacity: 1;
    }

    .active.carousel-item-left,
    .active.carousel-item-right {
      opacity: 0;
    }

    .carousel-item-next,
    .carousel-item-prev,
    .carousel-item.active,
    .active.carousel-item-left,
    .active.carousel-item-prev {
      transform: translateX(0);
      transform: translate3d(0, 0, 0);
    }
  }

  .carousel-caption {
    position: absolute;
    text-align: left !important;
    width: 95%;
    left: 10px;
    top: 30%;
    padding: 0;

    @include media-breakpoint-up(sm) {
      width: 80%;
      top: 40%;
    }

    @include media-breakpoint-up(lg) {
      left: 10%;
      top: 50%;
    }

    @include media-breakpoint-up(xl) {
      width: 50%;
    }

    .btn {
      margin-top: 0.9375rem;
    }

    p {
      font-size: 1.1rem;

      @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.2rem;
        line-height: 1.0rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.9rem;
        line-height: 1.9rem;
      }

      &.slideHeader {
        font-family: $dlrgFontBold;
        font-size: 1.8rem;
        line-height: 1.8rem;

        @include media-breakpoint-up(sm) {
          font-size: 2rem;
          line-height: 2rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 2.7rem;
          line-height: 2.7rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 3.2rem;
          line-height: 3.3rem;
        }
      }
    }
  }
}