img {
  max-width: 100%;
  height: auto;
}

.imgTransparent {
  opacity: 0.7;
}

.imgTransparent0 {
  opacity: 1.0;
}

.imgTransparent1 {
  opacity: 0.9;
}

.imgTransparent2 {
  opacity: 0.8;
}

.imgTransparent3 {
  opacity: 0.7;
}

.image {
  position: relative;
}

.image-caption {
  font-size: 0.9rem;
  padding: .4rem 0;
}

.img-cont {
  position: absolute;
  z-index: 100;

  &.dlrg-label {
    font-size: 1.125rem;
    top: 15px;
    left: 0;
    padding: 5px 10px;
  }

  &.text-bottom {
    bottom: 15px;
    left: 0;
    padding: 5px 0;
  }

  &.text-top {
    top: 0;
  }

  &.text-middle {
    top: 25%;
  }
}

.imgGradient,
.gallery a:hover .gallery-imgGradient {
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(rgba(0, 105, 180, 0.01), rgba(0, 105, 180, 0.9));
}

.ce-gallery {
  // Fluid Image Tags
  img,
  picture {
    display: block;
    width: 100%;
    height: auto;
  }

  .ce-row {
    display: flex;
    flex-wrap: wrap;

    &:only-child {
      margin-bottom: 0;

      .ce-column {
        padding-bottom: 0;
      }
    }
  }

  // Standard-Darstellung: Einspaltig, zentriert
  .ce-column {
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
    width: 100%;

    figure {
      margin: 0 auto;
    }
  }

  // Ab 768px: Zweispaltig
  @include media-breakpoint-up(md) {
    &:not([data-ce-columns="1"]) {
      .ce-column {
        flex-basis: 50%;
        box-sizing: border-box;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    figure {
      margin: 0;
    }
  }

  // Ab 992px: Ausgabe in einstellbaren Spalten
  @include media-breakpoint-up(lg) {
    @for $i from 1 through 8 {
      &[data-ce-columns="#{$i}"] {
        .ce-column {
          flex-basis: 100%/$i;
        }
      }
    }
  }
}

.ce-textpic {
  &.ce-right,
  &.ce-left {
    .ce-gallery {
      max-width: 50%;
    }

    @include media-breakpoint-down(xs) {
      .ce-gallery,
      .ce-bodytext {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    &.ce-right.ce-intext {
      display: flex;
      flex-direction: column;

      .ce-gallery {
        order: +1;
        margin-left: 0;
      }
    }
  }
}

.ce-uploads {
  span.ce-uploads-fileName {
    display: unset;
  }
}

iframe {
  max-width: 100%;
}