.page {
  .errorsite {
    background-image: url("/global/layout/2019/media/404.jpg");
    min-height: 100vh;
    background-size: cover;
    padding: 0.938rem;

    @media (min-width: 1070px) {
      padding-top: 2.75rem;
    }

    #main-header .container-fluid {
      background: none;
    }

    .error-headline {
      font-size: 2.125rem;

      @include media-breakpoint-up(xl) {
        font-size: 3.125rem;
      }
    }

    .error-text {
      font-size: 8.25rem;

      @include media-breakpoint-up(sm) {
        font-size: 16.25rem;
        margin-bottom: -55px;
      }

      @include media-breakpoint-up(md) {
        font-size: 15.25rem;
        margin-bottom: -55px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 25.25rem;
        margin-bottom: -110px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 31.25rem !important;
        margin-bottom: -142px;
      }

      img {
        height: 97px;
        margin-bottom: 23px;

        @include media-breakpoint-up(sm) {
          height: 180px;
          margin-bottom: 44px;
        }

        @include media-breakpoint-up(md) {
          height: 162px;
          margin-bottom: 46px;
        }

        @include media-breakpoint-up(lg) {
          height: 300px;
          margin-bottom: 105px;
        }

        @include media-breakpoint-up(xl) {
          height: 375px;
        }
      }
    }

    .input-group.col-md-12 {
      border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      background: white;
      border: 1px solid $dlrgColorBlue;
    }

    input {
      border: 0;
    }

    .error-content {
      .input-group {
        .btn {
          display: block;
          width: 38px;
          padding: 0;
        }
      }

      .fa.fa-search {
        padding: 0;
      }
    }

    .input-group-btn {
      box-sizing: border-box;
      padding-top: 8px;
    }

    .search-input {
      margin-top: 1.75rem;

      @include media-breakpoint-up(xl) {
        width: 50%;
      }
    }
  }
}