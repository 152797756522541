/****************************************/
/*          Trainingsgruppe             */
/****************************************/
.page {
  .training {
    @include media-breakpoint-down(sm) {
      .row {
        margin-bottom: 10px;
      }
    }
  }
}