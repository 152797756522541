.cc-d-none {
  display: none;
}

#cookie-consent {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  max-width: 60em;
  max-height: 100vh;
  margin: 0 auto;
  /*height: 100%;*/
  background-color: rgba(0, 0, 0, .5);
  font-size: 1rem;
  z-index: 9999;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.5);
  overflow-y: auto;

  p {
    margin: .5em 0;
    font-size: 0.9rem;
  }

  h2 {
    margin: 1em 0;
    font-size: 1rem;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td {
      border: 1px solid #cccccc;
      padding: .5em;
    }

    td:first-of-type {
      width: 8em;
    }
  }

  .cc-popup {
    background-color: white;
    width: 100%;
    overflow-y: auto;

    .cc-content {
      padding: 1rem;


      .cc-button-wrapper {
        text-align: center;

        button {
          display: inline-block;
          width: 15em;
          box-sizing: border-box;
          margin: 0.5rem;
        }

        #cc-allow-all,
        #cc-deny-all {
          background-color: $dlrgColorBlue;
          color: white;

          &:hover {
            background-color: $dlrgColorBlueHover;
          }
        }
      }

      #cc-details {
        margin-top: 2em;
      }
    }
  }

  button {
    border: 1px solid $dlrgColorBlue;
    background-color: white;
    padding: .5em 2em;
    margin-top: 1em;
    &:hover {
      background-color: $dlrgColorWhiteHover;
    }
  }
}


.cc-color-override-2038967837 {
  &.cc-window.cc-banner {
    color: rgb(255, 255, 255);
    background-color: rgba(0, 105, 180, 0.98);
  }

  a.cc-btn {
    color: $dlrgColorYellow;
    border-color: transparent;
    background-color: rgb(227, 6, 19);

    &:hover,
    &:focus {
      background-color: rgb(227, 6, 19);
    }
  }
}