.map {
  .jumbotron {
    background: $mainColorWhite;

    h2{
      color: $dlrgColorBlue;
      font-size: 50px;
    }

    .btn-select{
      width: 100%!important;
    }

    .lead{
      color: $dlrgColorBlue;
      font-size: 30px;
    }
  }

  .search-input {
    .input-group {
      border: solid 1px #0069b4;
      background-color: #ffffff;
      height: 52px !important;
      border-radius: 0.25rem;
      padding: 0;
    }

    input {
      border:0;
    }

    .fa.fa-search {
      padding-right: 0;
    }
  }
}


// Map
.maparea{
  position: relative;
  height: 900px;

  .gradienttop,
  .gradientbottom {
    display: none;
    position: absolute;
    height: 40px;
    width: 100%;
    z-index: 2;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .gradienttop{
    top: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+20,0+100 */
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 20%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );

  }

  .gradientbottom{
    bottom: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+1,1+80 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 80%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  }

  .mapOverlay{
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1;
    opacity: 0.3;
    display: none;

    span{
      width: 100%;
      position: absolute;
      color: #fff;
      margin-top: 12%;
      text-align: center;
      font-size: 30px;
    }
  }
}

div.olMap{
  position: relative;
}

#OpenLayers_Control_PanZoomBar_3{
  display: none;
}
// ENDE Map


// Map-Infopoint
#pop_FrameDecorationDiv_0, #pop_FrameDecorationDiv_1, #pop_FrameDecorationDiv_2, #pop_FrameDecorationDiv_3, #pop_FrameDecorationDiv_4{
  display: none;
}

#pop{
  width: 450px!important;
  background: #fff;
  -webkit-box-shadow: 10px 10px 21px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 21px -8px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 21px -8px rgba(0,0,0,0.75);
}

#pop_contentDiv{
  width: 100%!important;
  height: 100%!important;
  left: 0!important;
  top: 0!important;
  padding: 15px;
}

#pop_close{
  display: none;
}

.mapinfopoint {
  .header {
    color: $dlrgColorRed;
  }

  .box{
    display: block;
    padding: 5px;
    background: $mainColorLightGrey;
    margin: 15px 0;
    border-radius: 8px;
    color: $mainColorGrey !important;
  }
}

#detailCnt {
  h2 {
    color: $dlrgColorRed;
  }

  label{
    width: 350px;
  }
}

.map-scroll {
  &:before {
    content: 'Use ctrl + scroll to zoom the map';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
    font-size: 34px;
  }

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: '';
    background: #00000061;
    z-index: 999;
  }
}
// ENDE Map-Infopoint