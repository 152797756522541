.sticky-seite {
  position: fixed;
  left: 100%;
  transition: left 0.3s ease 0s;
  z-index: 999;
  height: auto;
  box-sizing: border-box;
  min-height: 59px;
  display: block;

  .icon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 100%;
    width: 86px;
    height: 76px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
    // centering icons
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: auto;
      width: 47px;
      display: block;
    }

    .fa,
    .fal,
    .far,
    .fas,
    .fab {
      font-size: 2.5rem;
    }
  }

  &.sticky-contact,
  &.sticky-basket {
    &,
    p {
      height: 45px;
      margin: 0;
    }
  }

  .content {
    font-size: 1rem;
    border-left: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;

    .sticky {
      padding: 2.1875rem 0;
    }

    .headline {
      padding: 0.9375rem;
      width: fit-content;
    }

    .iconContent img {
      height: 45px;
      width: auto;
    }
  }

  //border-right deaktivieren, wenn content übereinander
  @include media-breakpoint-down(sm) {
    .border-right {
      border: none !important; //important, weil Bootstrap _border.scss
    }
  }

  &.sticky-contact {
    $widthContactSticky: 650px;

    bottom: 475px;
    width: $widthContactSticky;
    background-color: $mainColorWhite;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    @media (max-height: 800px) { //erst ab 768px x 860px B x H anzeigen
      display: none;
    }

    &.open {
      left: calc(100% - #{$widthContactSticky});
    }

    .icon,
    .content {
      border-color: $dlrgColorBlue;
    }

    .icon {
      background-color: rgba($mainColorWhite, .7);
      color: $dlrgColorBlue;
    }

    .content {
      background: $mainColorWhite;
    }
  }

  &.sticky-basket {
    $widthBasketSticky: 180px;

    bottom: 555px;
    width: $widthBasketSticky;

    @media (max-height: 800px) { //erst ab 768px x 860px B x H anzeigen
      top: 186px;
    }

    &.open {
      left: calc(100% - #{$widthBasketSticky});
    }

    .icon,
    .content {
      background-color: rgba($dlrgColorBlue, .9);
      border-color: $mainColorWhite;
      height: 76px;

      &,
      a {
        color: $mainColorWhite;
      }

      a:hover {
        color: $dlrgColorWhiteHover;
        text-decoration: underline;
      }
    }

    .content {
      border-left: none;
      line-height: 1;

      .sticky {
        padding: 0;
        padding-right: 1rem;

        .basket-price {
          padding-top: 20px;
          font-size: 1.5rem;
        }
      }
    }

    .amount-bubble {
      position: absolute;
      text-align: center;
      background: $dlrgColorRed;
      border-radius: 50%;
      color: $mainColorWhite;
      top: -15px;
      left: -15px;
      font-size: 1rem;
      padding: 4px 0;
      width: 30px;
      height: 30px;

      span {
        display: block;
        width: 100%;
      }
    }
  }
}