.personen {
  img {
    width:100%;
    height:auto;
  }
  .row {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  dd {
    padding-left: 1rem;

    ul {
      padding-left: 1.5rem;
    }
  }
}

.personen-grid {
  text-align: center;

  > .row {
    > .p-2 {
      margin-bottom: 1.25rem;

      figure {
        position: relative;
        width: max-content;
        max-width: 100%;
        height: auto;
        max-height: 550px;
        margin: 0 auto;
        z-index: 100;

        .img-fluid {
          width: auto;
        }

        .teaser_hover {
          background-color: $dlrgColorBlue;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          color: #fff;
          opacity: 0;
          z-index: 50;
          display: flex;
          justify-content: center;
          flex-direction: column;

          p {
            margin: 1rem 0;
          }

          a {
            color: #fff;
          }
        }

        .teaser_hover:hover, a:hover {
          opacity: 0.85;
          transition: 0.4s 0s;
          -webkit-transition: 0.9s 0s;
        }

        h3 {
          text-transform: uppercase;
        }

        figcaption {
          position: absolute;
          bottom: 10%;
          background-color: $dlrgColorBlue;
          color: #fff;
          padding: 0.5rem 1rem 0 1rem;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          width: 70%;
          z-index: 30;
        }

        .function {
          font-size: 0.9em;
        }

        .fa {
          font-size: 2.2rem;
        }
      }
    }
  }

  .btn {
    color: #003d55;
  }

  .btn:hover {
    background-color: #b1c903;
    border-color: #b1c903;
  }
}