.tx-indexedsearch-res {
  .card-body {
    padding: 1.25rem;
  }
  .tx-indexedsearch-description {
    margin-bottom: 0;
  }
  .card-footer {
    .row, p {
      margin-bottom: 0;
    }
  }
}