.fancybox-container {
  .fancybox-bg {
    background: $dlrgColorBlue !important;
  }

  .fancybox-inner {
    .fancybox-stage {
      .fancybox-slide {
        .fancybox-content {
          padding: 44px 44px 100px!important;
          pointer-events: none;
          cursor: default;

          .fancybox-image {
            height: auto;
            max-width: 100%;
          }

          a,
          button {
            pointer-events: auto;
            cursor: pointer;
          }
        }
      }
    }
  }
}

#detail {
  .fancybox-button {
    display: none;
  }
}