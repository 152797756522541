.flip-card {
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-card-inner {
  position: relative;
  padding-bottom: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card {
  &:hover,
  &:active, {
    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }

  // für Touchdevices Icon zum Drehen anzeigen
  .flip-card-inner {
    .fa,
    .fas,
    .far,
    .fal,
    .fab {
      position: absolute;
      top: 15px;
      right: 15px;
      color: $dlrgColorYellow;
      display: none;

      @include touchdevice {
        display: block;
      }
    }
  }
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  overflow: hidden;
}

.flip-card-front {
  img {
    height: 100%;
    object-fit: cover;
  }
}

.flip-card-back {
  transform: rotateY(180deg);
}