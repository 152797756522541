.page {
  .accordion {
    .accHeader {
      &.accordion_trigger {
        padding: 1.875rem;
      }

      .btn {
        margin: 0;
        border-radius: 0;
        padding: 1rem 0;
        width: 100% !important;
        text-align: left;
        font-size: inherit;
        white-space: inherit;

        .accTitle {
          width: 100%;
          padding-left: 1rem;

          @include media-breakpoint-down(md) {
            font-size: 0.66em;
          }

          > div {
            align-self: center;
          }
        }

        .plus {
          font-size: 2.4rem;

          .fa-plus {
            display: none;
          }

          .fa-minus {
            display: block;
          }
        }

        &.collapsed {
          .plus {
            font-size: 2.4rem;

            .fa-plus {
              display: block;
            }

            .fa-minus {
              display: none;
            }
          }
        }
      }
    }

    .card {
      border-bottom: 1px solid rgba(0, 0, 0, .125);
      height: unset;

      .card-header {
        padding: 0;
        background: unset;
        border: 0;
      }

      .card-body {
        padding: 0.5rem 1.25rem 0 1.25rem;

        @include media-breakpoint-up(md) {
          padding: 0.625rem 2.1875rem 0 2.1875rem;
        }
      }
    }
  }
}