.page {
  footer.page-footer {
    font-size: 0.8rem;
    color: $mainColorGrey;
    background-color: $mainColorLightGrey;
    padding-top: 1rem;

    h5 {
      color: $mainColorGrey;
      font-size: 1rem;
      font-weight: bold;
    }

    .col-md-2 {
      padding-right: 0;
      padding-left: 0;
    }

    .navbar {
      padding: 0.625rem 0;
    }

    a.nav-link {
      color: $mainColorGrey;
      font-size: 1rem;
      font-weight: bold;
    }

    .navbar-nav {
      ul {
        list-style: none;
      }

      li.nav-item {
        ul {
          padding: 0;

          li {
            padding: 0.3em 0;

            // MAXIMUM 20 Level2 Nav-Items!
            &:nth-child(n+21) {
              display: none !important; //important für absolute Absicht!
            }

            a {
              color: $mainColorGrey;

              &:hover {
                text-decoration: none;
                opacity: 0.8;
              }

              p {
                margin: 0;
                hyphens: auto;

                &::before {
                  content: "";
                  background: url(/global/layout/2019/images/arrow_grey.svg) no-repeat scroll 0 0 / contain;
                  width: 12px;
                  height: 10px;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }
        }

        a.nav-link p {
          margin: 0;
        }
      }
    }

    .sitebar {
      .navbar-nav {
        .row {
          li {
            ul {
              display: block;

              @include media-breakpoint-down(lg) {
                display: none;
              }
            }

            span.updown {
              content: "";
              background: none;
              width: 12px;
              height: 10px;
              display: inline-block;
              vertical-align: middle;
              transform: rotate(90deg);

              @include media-breakpoint-down(lg) {
                content: "";
                background: url(/global/layout/2019/images/arrow_grey.svg) no-repeat scroll 0 0 / contain;
                width: 12px;
                height: 20px;
                display: inline-block;
                vertical-align: middle;
                transform: rotate(90deg);
                margin: 13px 20px;
                float: left;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .adress {
      margin: 0.5rem 0;
    }

    .fa {
      color:$mainColorWhite;
      background: $dlrgColorBlue;
    }

    .bauchbinde {
      background: $dlrgColorRed;
      text-align: right;

      img {
        height: 20px;

        @include media-breakpoint-up(xl) {
          height: 30px;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      .linksGld {
        float: right;
      }
    }

    @include media-breakpoint-up(md) {
      .btn {
        width: auto;
      }
    }
  }
}
