.page {
  .teaserbox {
    > a > div,
    > div {
      height: 410px;
    }

    .imgTransparent,
    picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .img-cont {
      padding-right: 1rem;
    }

    .hovertext {
      display: none;
      color: $mainColorWhite;
      padding: 40px;
      padding-top: 20px;
      height: 220px;
      overflow: hidden;

      @include touchdevice {
        // alle auf important, dass inline-Werte überschrieben werden
        // verhindert ungewollten JS-Hover-Effekt bei Touch-Geräten
        display: block !important;
        height: fit-content !important;
        max-height: 250px !important; // Höhe-Teaserbox:410px abzgl. 160px rote/weiße Boxen
        padding-top: 1rem !important;
        padding-bottom: 0 !important;
      }
    }

    .linktext {
      position: absolute;
      right: 30px;
      bottom: 20px;
      color: $mainColorWhite;
      display: none;

      img {
        width: 30px;
        height: 50px;
        margin-left: 10px;
      }
    }
  }
}

.page {
  .teaserbox-Text-Button {
    .img-cont {
      height: 100%;
      padding: 1.5625rem;
      position: relative;
      top: 0;
      display: flex;
      flex-direction: column;

      p:last-of-type {
        margin-bottom: 50px;
        flex-grow: 1;
      }

      .text-center {
        padding: 0.625rem;
      }

      .text-top {
        left: 0;
        width: 100%
      }
    }

    img.imgTransparent {
      opacity: 0.7;
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .bgBlue {
      overflow: hidden;
    }
  }
}
