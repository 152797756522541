@mixin teaser() {
  color: $mainColorWhite;
  background: $backgroundBlue;
  padding: 70px 50px;
  position: relative;
  margin: 12px 0;
  height: 100%;

  .dlrg-label {
    font-size: 1.125rem;
    top: 20px;
    padding: 5px 10px;
    position: absolute;
  }
}

@mixin sameHeight-row() {
  display: flex;
  flex-wrap: wrap;
}

@mixin sameHeight-box() {
  display: flex;
}

.sectionTeaser {
  .row {
    margin: 0;
  }
}

.page {
  .teaser {
    .container-fluid {
      margin: 0;
      padding: 0;
    }
  }
}

.sectionTeaser,
.teaserbox {
  .redBox {
    font-size: 1.25rem;
    font-weight: bold;
    width: fit-content;
    padding: 0.625rem;
  }

  .whiteBox,
  a .whiteBox{
    color: $mainColorBlack;
    width: fit-content;
    padding: 0.625rem;
    margin-top: 0.625rem;
    margin-left: 1.875rem;

    &:empty {
      visibility: hidden;
    }
  }
}

.teaserfield,
.teaser {
  &.container {
    .row {
      > div:first-of-type {
        padding-left: 0 !important; // important weil _spacing.scss schon important
      }

      > div:last-of-type {
        padding-right: 0 !important; // important weil _spacing.scss schon important
      }

      @include media-breakpoint-down(sm) {
        > div {
          padding-left: 0 !important; // important weil _spacing.scss schon important
          padding-right: 0 !important; // important weil _spacing.scss schon important
        }
      }

      .etb,
      .seminar {
        margin: 0;
        padding: 0;
        min-height: 350px;

        .bgBlue {
          overflow: hidden;
          height: 100%;
        }

        .imgTransparent {
          opacity: 0.4;
        }

        .img-fluid {
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
        }

        .container {
          position: relative;
        }

        .textContent {
          top: 0;
          width: 100%;
          position: relative;
          padding: 2rem;

          .dlrg-label {
            top: 30px;

            & + h3 {
              margin-top: 50px;
            }
          }

          .btn-red {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}

.teaser {
  .etb,
  .seminar {
    .teaser-image {
      display: flex;
      min-height: 350px;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      img {
        object-fit: cover;
      }
    }

    .teaser-background {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}