/****************************************/
/* Kalender Monatsansicht               */
/****************************************/
#calendar_ex {
  font-size: 1.25rem;
  height: min-content;
  min-height: 85vH;

  .fc-view {
    position: unset !important;
  }

  .fc-header-toolbar {
    flex-wrap: wrap;

    .fc-toolbar-chunk {
      margin-top: 1rem;
      @include media-breakpoint-down(sm) {
        flex-grow: 1;
      }

      &:first-child {
        @include media-breakpoint-down(sm) {
          flex-basis: 67%;
        }
      }

      &:last-child {
        @include media-breakpoint-down(sm) {
          flex-basis: 33%;
          display: flex;
          justify-content: end;
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-down(sm) {
          order: +1;
          flex-basis: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .btn-group {
        button {
          hyphens: none;
          margin-top: 0;
        }
      }
    }
  }

  // Kalender soll auch bei vielen Terminen nicht scrollbar werden.
  .fc-scroller-liquid-absolute {
    position: unset !important;
    overflow: unset !important;
    min-height: 68vH !important;
  }

  .fc-daygrid-day-events {
    .fc-event-main {
      .fc-event-main-frame {
        .fc-event-time {
          flex-shrink: 0;
        }
      }
    }
  }

  a.fc-daygrid-event {
    cursor: pointer;
  }
}

/****************************************/
/* Kalender Liste Gross                 */
/****************************************/
#fullcalendarListeBig {
  .fc-list-empty-cushion {
    margin: 2em 0;
  }
}

/****************************************/
/* Kalender Karusell                    */
/****************************************/
.termin {
  &.successStories {
    padding-bottom: 60px;

    .carousel {
      &.slide {
        .carousel-indicators {
          left: 0;
          margin: 0 15% -50px 15%;
        }

        .carousel-inner {
          @include media-breakpoint-up(sm) {
            width: 90%;
            margin: 0 5%;
          }

          .card-group {
            &.row {
              margin: 0; // weil _grid.scss -15px margin

              .card {
                .card-header {
                  background: $headerColorLightGrey;
                  border: 0;
                  border-top-left-radius: .5rem;
                  border-top-right-radius: .5rem;
                  min-height: 85px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                }

                .card-body,
                .card-footer {
                  background: $mainColorLightGrey;
                  padding: 15px;
                  border: 0;
                }

                .card-footer {
                  border-bottom-left-radius: .5rem;
                  border-bottom-right-radius: .5rem;
                }
              }
            }
          }
        }

        .carousel-control-prev,
        .carousel-control-next {
          color: $mainColorGrey;
          width: 7.5%;
        }
      }
    }
  }
}

/****************************************/
/* Termin Legende                       */
/****************************************/
.termin {
  &.legende {
    margin-top: 1.5rem;

    .kategorie {
      display: flex;
      flex-wrap: wrap;
      margin: 0;

      > div {
        padding: 5px;
        display: flex;
      }

      span {
        flex-grow: 1;
        padding: 0 4px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 0.85em;
        line-height: 1.4;
        border-radius: 3px;
        border: 1px solid $gray-800;
      }
    }
  }
}
