// Suche ausblenden bis Lösung gefunden ist
.page {
  .openCloseSearch,
  .navbar .navbar-brand ~ .form-inline,
  .menusearchontainer {
    display: none !important; // Absolute Absicht
  }
}


// Variablen für max-Height Desktop-Menü
$desktopmenu-verkleinern: 699.98px;

// breadcrumbs auf Startseite nicht anzeigen
#startseite .page .breadcrumb-nav .container {
  display: none;
}


.navbar.navbar-expand-lg {
  padding: 0;
}


// Header und Breadcrumb
.page {
  #main-header {
    .metaNav {
      position: relative;
      margin: 0;
      left: 0;
      top: 0;
      background: $headerColorLightGrey;

      @include media-breakpoint-up(xl) {
        display: block !important;
      }

      .navbar-nav {
        flex-direction: unset;
        flex-wrap: wrap;

        a.nav-link {
          color: $mainColorGrey;
          font-size: 0.85rem;

          @include media-breakpoint-up(xl) {
            font-size: 0.95rem;
          }
        }
      }

      .navbar-expand-lg {
        .navbar-nav {
          .nav-link {
            padding: 8px 6px !important;
          }
        }
      }
    }

    .container-fluid {
      position: relative;
      margin: 0;
      left: 0;
      top: 0;
      background: $dlrgColorRed;
      transition: 300ms ease;

      &.scrolled {
        z-index: 1000;
        max-width: unset;
        top: 0;
        position: fixed !important;
        width: 100%;
        margin: 0;
        padding: 0 !important;
      }

      .searcharea {
        height: auto;
        padding: 2.5rem;
        background: $mainColorWhite;
        display: none;
        z-index: 110;
        width: 100%;

        .container .row {
          padding: 0 !important;
        }
      }
    }

    .container {
      .row {
        padding-bottom: 1.25rem;
      }
    }

    .openCloseSearch {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .mainnav {
      @include media-breakpoint-down(lg) {
        display: none;
      }

      @include mobilemenu {
        display: none;
      }

      .navbar-brand {
        padding: 0.5333rem;
        margin-right: 0;

        img {
          height: 20px;
          min-width: 120px;

          @include media-breakpoint-up(lg) {
            height: 30px;
            min-width: 194px;
          }

          @media (min-width: 1400px) {
            //Kein BS-Breakpoint, da sonst das Logo zu schnell zu riesig wird!
            height: 54px;
            min-width: 347px;
          }
        }
      }

      .navbar-nav {
        a {
          &.nav-link {
            color: $mainColorGrey;
            font-size: 1.625rem;
            padding: 2.1875rem 1.25rem;
            box-sizing: border-box;
            transition: all 0.3s;
            border-bottom: 4px solid $dlrgColorRed;

            @include media-breakpoint-up(lg) {
              padding: 1.7rem 1.0rem 1.1rem 1.0rem;
              font-size: 1.2rem;
            }

            @include media-breakpoint-up(xl) {
              padding: 1.8rem 1rem 1.3rem;
            }
          }

          &.active,
          &.selected {
            border-bottom: 4px solid $mainColorWhite;
            color: $dlrgColorRed;
          }
        }
      }

      .navbar {
        .btn.btnSpenden {
          margin: 0.625rem 0.625rem 0.625rem 0;
          font-size: 0.8rem;

          @include media-breakpoint-up(md) {
            font-size: 1.0rem;
            width: auto;

            img {
              display: block;
              float: left;
              height: 1.5rem;
              padding-right: 1.2rem;
            }
          }

          @include media-breakpoint-up(lg) {
            font-size: 1.2rem;
          }

          @include media-breakpoint-up(xl) {
            img {
              height: 25px !important;
              padding-right: 1.1rem;
            }
          }
        }
      }

      .form-inline.normal {
        position: relative;
        right: auto;
      }

      .fa.fa-search {
        padding-right: 1.1875rem;
        margin-left: -10px;

        @include media-breakpoint-up(xl) {
          font-size: 1.7rem;
        }
      }

      &:not(.mobilenav) {
        .nav-link p {
          margin: 0;
        }

        .level2.subnav1 {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          min-height: calc(340px + 70px);
          max-height: calc(2 * 70px + 10 * 48px);
          position: relative;
          padding: 70px 0;

          @media (max-height: $desktopmenu-verkleinern) {
            padding: 30px 0;
            max-height: calc(2 * 30px + 10 * 38px);
            font-size: 0.9em;
          }

          li {
            a {
              width: 80%;
              max-width: 80%;
              padding: 0.625rem 0.625rem;
              box-sizing: border-box;
              border-bottom: 1px solid $mainColorGrey;
              display: flex;
              justify-content: space-between;

              @media (max-height: $desktopmenu-verkleinern) {
                padding: 0.3rem 0.625rem;
              }

              &:hover,
              &:active {
                background-color: $mainColorLightGrey;
              }

              .selected {
                color: $dlrgColorRed;
              }

              p {
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &:hover,
            &:active {
              background-color: #ebebeb;
            }
          }

          > li {
            width: 50%;

            a:focus {
              background-color: inherit; //focus sieht manchmal komisch aus
            }

            ul.level3 {
              min-height: 100%;
              height: fit-content;
              width: 50%;
              visibility: hidden;
              opacity: 0;
              transition: opacity 0.2s, visibility 0.2s;
              z-index: +1; //fixes Icons over level3
              border-bottom: 2px solid rgba(87, 87, 86, .3);
              position: absolute;
              top: 0;
              background: #ebebeb;
              padding: 70px 0;

              @media (max-height: $desktopmenu-verkleinern) {
                padding: 30px 0;
              }

              &.active {
                visibility: visible;
                opacity: 1;
                background: #ebebeb;
              }

              li a {
                width: 80%;
                margin: 0 5%;
                display: block;
              }

              a,
              li {

                &:hover,
                &:active {
                  background-color: #e0e0e0;
                }
              }
            }

            &:nth-child(-n+10) {

              //Element 10 abwärts
              ul.level3 {
                left: 50%;
                right: auto;
              }
            }

            &:nth-child(n+11) {

              //Element 11 aufwärts
              ul.level3 {
                right: 50%;
                left: auto;
              }
            }
          }
        }
      }
    }

    .navbar {
      @include media-breakpoint-down(md) {
        flex-direction: inherit;
        align-items: baseline;
      }

      @include media-breakpoint-up(xl) {
        justify-content: flex-start !important;
      }
    }

    //IE fixing
    //all !important necessary to get IE to eval CSS properly.
    _:-ms-lang(x),
    .mainnav:not(.mobilenav) .level2 {
      flex-direction: row !important;

      > li {

        &:nth-child(odd) {

          //Element 10 abwärts
          ul.level3 {
            left: 50% !important;
            right: auto !important;
          }
        }

        &:nth-child(even) {

          //Element 11 aufwärts
          ul.level3 {
            right: 50% !important;
            left: auto !important;
          }
        }
      }
    }

    .mobilenav {
      display: none;

      nav {
        justify-content: unset;
      }

      form {
        margin-left: auto;
      }

      @include media-breakpoint-down(lg) {
        &,
        .navbar-toggler {
          display: block;
        }
      }

      @include mobilemenu {
        &,
        .navbar-toggler {
          display: block;
        }
      }

      .navbar-brand {
        width: auto;

        @include media-breakpoint-up(sm) {
          img {
            height: 40px;
            min-width: 258px;
          }
        }
      }

      .navbar.navbar-expand-lg {
        @include media-breakpoint-up(sm) {
          padding: 0 0.9375rem;
        }
      }

      .navbar-toggler {
        padding: 1.375rem 0;

        .navbar-toggler-icon {
          background-image: url("/global/layout/2019/images/menu.svg") !important;
        }
      }
    }

    #navbarMainMenu {
      @include media-breakpoint-down(md) {
        display: none !important;
      }

      .navbar-nav {
        a {
          &.nav-link {
            color: $mainColorWhite;
            height: 100%
          }

          &.active {
            border-bottom: 4px solid $mainColorWhite;
          }

          &:hover {
            text-decoration: none !important;
          }
        }
      }

      .subnavleft {
        .title {
          padding-top: 1.25rem;
        }
      }
    }

    #navbarMainMenuMobile {
      border-bottom: 1px solid $mainColorGrey;
      background: $mainColorWhite;
      max-height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;

      > ul {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        transition: opacity 0.5s ease 0s, visibility ease 0s 0.5s, transform ease 0.5s 0s;
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        transition-delay: 0s;

        &.closed {
          transform: translateX(-100%);
          transition: opacity ease 0.5s 0s, visibility ease 0s 0.5s, transform ease 0.5s 0s;

          ul.level2.closed {
            transform: translateX(-5%);
            transition: opacity ease 0.5s 0s, visibility ease 0s 0.5s, transform ease 0.5s 0s;
          }
        }

        ul {
          transform: translateX(100%);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.5s ease 0s, visibility ease 0s 0.5s, transform ease 0.5s 0s;
          border: none;

          &.open {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
            display: block;
          }

          &.level2 {
            li:hover {
              a {
                color: $mainColorWhite;
              }
            }
          }

          &.level3 {
            padding: 0;
            height: auto;
            background: $mainColorWhite;

            li {
              margin-left: 5%;
              width: 100%;
            }
          }
        }
      }

      .navbar-nav {
        li {
          &:hover {
            a {
              span {
                background-size: contain;
                background: url("/global/layout/2019/images/whiteArrow.svg") no-repeat fixed right;
                border-left: 2px solid $mainColorWhite;
              }
            }
          }

          &.nav-item {
            border-top: 1px solid #575756;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;

            a {
              border-bottom: none;
              transition: all 0.2s;

              p {
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: calc(100vw - 90px);
              }
            }
          }

          ul.level2 {
            li {
              span {
                border-left: 1px solid $mainColorGrey;
                padding: .5rem 1.2rem;
              }

              &.nav-item {
                &:hover {
                  a {
                    color: $mainColorBlack;
                  }
                }

                a {
                  &:hover {
                    background: $dlrgColorBlueHover;
                    color: $mainColorWhite;
                  }

                  &.back:hover {
                    color: $mainColorWhite;
                  }
                }

                span:hover {
                  background: $dlrgColorBlue;
                  color: $mainColorWhite;
                }
              }

              a.back {
                background: #e6e6e6;
                padding: 0.625rem 0 0.625rem 1.5rem;
                width: 100% !important;

                .fa {
                  font-size: 1rem;
                }
              }
            }

            a.dropdown-item {
              padding: 0.9375rem 0 0.9375rem 1.6rem;
              width: 100%;
              clear: left;
              float: left;

              &:hover {
                color: $mainColorWhite;
              }
            }

            ul.level3 span {
              padding-top: 9px;
            }
          }

          span {
            color: $dlrgColorBlue;
            padding: 0.67rem 1.8rem;
            width: 60px;
            border-left: 1px solid $mainColorGrey;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              background: $dlrgColorBlueHover;
              color: $mainColorWhite;
            }
          }

          .fa {
            font-size: 2.15rem;
          }
        }

        a.nav-link {
          padding: 0.9375rem 0 0.9375rem 1.6rem;
          width: 100%;
          font-size: 1.2rem;
          float: left;

          &:hover {
            background: $dlrgColorBlueHover;
            color: $mainColorWhite;
          }
        }
      }

      .no_pic {
        display: none;
      }
    }

    .container.mainnav,
    .metaNav .container {
      max-width: 100% !important;

      &.mobilenav {
        max-width: 100% !important;
        padding: 0 !important;
      }
    }

    .navhover {
      position: absolute;
      width: 100%;
      height: auto;
      padding: 0 50px;
      box-sizing: border-box;
      left: 0;
      font-size: 1.125rem;
      color: $mainColorGrey;
      background-color: $mainColorWhite;
      z-index: 9999;
      display: none;
      border-bottom: 2px solid rgba(87, 87, 86, .3);

      @include media-breakpoint-down(lg) {
        padding: 0 15px;
      }

      .flex {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
      }

      ul {
        li {
          display: flex;
          justify-content: center;

          a {
            color: $mainColorGrey;
            border: none;
            width: 85%;
          }

          a:hover,
          &.active > a,
          &.selected > a {
            color: $mainColorGrey;
          }
        }
      }
    }

    .subnav2 {
      width: 100%;
      position: absolute;
      left: 100%;
      top: 0;
      padding: 70px 0 140px;
      box-sizing: border-box;
      background-color: #0067b1;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.5s, opacity 0.5s linear;

      @media (max-height: $desktopmenu-verkleinern) {
        padding: 30px 0 140px;
      }

      &.active {
        visibility: visible;
        opacity: 1;
      }

      li {
        a:hover {
          background-color: inherit;
        }
      }
    }

    .subnavleft {
      width: 31%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 70px 0 0;

      @media (max-height: $desktopmenu-verkleinern) {
        padding: 30px 0 0;
      }

      a {
        color: $mainColorGrey;
        border-bottom: none !important;

        &:hover {
          color: #e30613 !important;
          text-decoration: none;
        }

        i {
          margin-right: 10px;
        }
      }

      img {
        margin-bottom: 25px;
        width: 100%;
        height: auto;
      }

      .image {
        .subnavTextBox {
          position: absolute;
          top: 25%;
          padding-right: 1rem;

          span {
            padding: 10px 20px;
            color: $dlrgColorYellow;
            background-color: $dlrgColorRed;
            font-size: 26px;
          }

          p {
            margin-top: 1rem;
            margin-left: 1rem;
            padding: 7px 20px;
            color: $headerColorGrey;
            background-color: $mainColorWhite;
            font-size: 20px;
          }
        }
      }
    }

    // MAXIMUM 20 Level2 Nav-Items!
    // .sitemap ul>li:nth-child(n+21),
    .mainnav.mainnav:not(.mobilenav) .level2 > li:nth-child(n+21),
    .mainnav.mobilenav .level2 > li:nth-child(n+22) {
      display: none !important; //important für absolute Absicht!
    }

    .breadcrumb-nav {
      z-index: 99;
      background: rgba(255, 255, 255, 0.7) !important;
      font-size: 1rem;
      margin: unset !important;
      left: unset !important;
      top: unset !important;

      .breadcrumb {
        border-radius: 0;
        background: none;
        padding: 1rem 1rem 1rem 0;
        margin: 0;
        font-size: 1rem;

        @include media-breakpoint-down(md) {
          font-size: 0.85rem;
        }

        @include media-breakpoint-down(sm) {
          font-size: 0.7rem;
        }

        ol a,
        a {
          color: $mainColorGrey;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      .container {
        max-width: 1750px !important;
      }
    }
  }

  #headerCarousel {
    //kein Slider darf den Seiten-Fold verdecken
    div[class*="slider"] {
      max-height: calc(100vh - 166px);
    }

    //Setup Slider heights for mobile devices, mobile first
    //Berechnungsgrundlage 60% der Fensterhöhe (viewingheight)
    $maxMobileViewingHeight: 60vh;

    .slider-100 img {
      height: (1 * $maxMobileViewingHeight);
    }

    .slider-80 img {
      height: (0.8 * $maxMobileViewingHeight);
    }

    .slider-60 img {
      height: (0.6 * $maxMobileViewingHeight);
    }

    .slider-40 img {
      height: (0.4 * $maxMobileViewingHeight);
    }

    //general styling for slider image
    .carousel-item {
      img {
        display: block;
        width: 100%;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
          height: auto; //unset height on big screens, sizing from T3 image cropping
        }
      }

      .carousel-caption p {
        text-shadow: 0 3px 15px $mainColorGrey;
      }
    }
  }

  .page-disruptor {
    padding: 1rem;
    text-align: center;
    margin: 0;
    z-index: 90;
    width: 100%;
    opacity: 0.9;

    h3,
    a,
    p {
      color: inherit;
    }

    a,
    p {
      font-size: 1.1em;
      margin: 0;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

// ENDE Header und Breadcrumb


// Suchfeld Start
.page {
  #main-header {
    .search-input {
      padding: 0 15px 0 0;

      .suchen {
        display: flex;
        align-items: center;
        justify-content: center;

        .input-group {
          padding-left: 0;
        }
      }
    }

    // Suche Mobiles Menü
    .menusearchontainer {
      .search-input {
        border: none;
        padding: 3px 15px;

        &.row {
          padding: 3px 15px;

          .suchen {
            width: 60px;

            .fa.fa-search {
              font-size: 1.5rem;
              padding: 5px 0;
            }
          }
        }

        input#topSearch {
          border: none;
        }
      }

      span.input-group-btn {
        margin-top: 5px;
      }
    }

    // ENDE Suche Mobiles Menü
  }
}

.search-input {
  input {
    border: 0;
    box-shadow: none;
    height: 50px;
  }

  button {
    background: none;
    border: 0;
    color: #0069b4;
    padding: 8px 25px;
    width: 15px;
    box-sizing: border-box;

    &:hover {
      border: 0;
      box-shadow: none;
    }
  }

  .glyphicon-search {
    font-size: 23px;
  }

  .btn-info:hover {
    background: none;
  }
}

/* Suchfeld Ende*/
