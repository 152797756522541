.sitemap {
  padding: 8rem 0;

  a {
    color: $mainColorGrey;
    margin: 1rem 0;

    &:hover {
      color: $dlrgColorBlueHover;
    }
  }

  ul {
    margin: 0;

    li {
      a {
        color: $mainColorGrey;
        margin: 1rem 0;

        &:hover {
          color: $dlrgColorBlueHover;
        }

        ul {
          li {
            font-size: 1rem;
          }
        }
      }
    }
  }
}