.page {
  .fuenfBildText {
    &.container {
      max-width: 1000px !important; //weil _grid.scss schon important
    }

    .row {
      height: 100%;
    }

    .imgSmall,
    .imgBig {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .img-cont {
      .redBox,
      .whiteBox {
        width: fit-content;
        padding: 0 .75rem;
      }

      .whiteBox {
        margin-top: 0.5rem;
        margin-left: 1rem;
      }
    }

    .TextBox {
      padding: 5rem;

      @include media-breakpoint-down(xl) {
        padding: 4rem;
      }

      @include media-breakpoint-down(lg) {
        padding: 3rem;
      }

      @include media-breakpoint-down(md) {
        padding: 2rem;
      }

      @include media-breakpoint-down(sm) {
        padding: 1rem;
      }

      ul {
        list-style: none;
        padding-left: 0;

        li::before {
          font-family: $FontAwesomeFamily;
          font-weight: 300;
          content: "\f105";
          margin-right: 0.75rem;
          width: 2rem;
          text-align: center;
        }
      }
    }

    .ImgBig {
      display: flex;
      flex-direction: column;

      picture {
        flex-grow: 1;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}