#main-content {
  hyphens: auto;
}

/* responsive ; z.B. für Seminarapp, newsapp etc. */
@include media-breakpoint-down(sm) {
  .hide-on-tablet {
    display: none;
  }
}

@include media-breakpoint-down(xs) {
  .hide-on-mobile {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .page .container {
    max-width: 1590px !important;
  }
}

a {
  color: $dlrgColorBlue;
}

.display-none {
  display: none !important; //overwrite any display property to hide element
}

.realtive {
  position: relative;
}

/* page */
.page {
  font-size: 1.125rem;
  font-family: $dlrgFontRoman;
  color: $mainColorGrey;

  @include media-breakpoint-up(xl) {
    font-size: 1.25rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $dlrgFontRoman;

    &.blue {
      color: $dlrgColorBlue;
    }
    &.red {
      color: $dlrgColorRed;
    }
    &.yellow {
      color: $dlrgColorYellow;
    }
    &.grey {
      color:$headerColorGrey;
    }
  }

  h1,
  h2 {
    font-size: 2.063rem;
    font-weight: bold;
    color: $dlrgColorBlue;
    word-wrap: break-word;

    @include media-breakpoint-up(lg) {
      font-size: 2.188rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 2.375rem;
    }
  }

  h2.textWhite,
  h2.text-white {
    color: $mainColorWhite;
  }

  h3 {
    font-size: 1.625rem;

    @include media-breakpoint-up(xl) {
      font-size: 2rem;
    }
  }

  h4 {
    font-size: 1.875rem;
  }

  h4.textGrey {
    color: $mainColorGrey;
  }

  h5 {
    font-size: 1.5rem;
  }
  
  #main-content {
    .container,
    .container-fluid {
      .container,
      .container-fluid {
        padding: 0;

        // wenn .container kein padding hat, darf .row kein margin (-15px) haben
        .container,
        .container-fluid {
          .row {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }

      // Wenn Container Hintergrund hat, darf padding nicht weggenommen werden
      picture ~ .imgGradient {
        & ~ .container,
        & ~ .container-fluid {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  .jumbotron {
    background: none;
  }

  .card {
    background: none;
    border: 0;
    border-radius: 0;

    .card-body {
      padding: 2.5rem 0;

      &.bgBlue.textWhite,
      &.bgBlue.text-white {
        background: $dlrgColorBlue;
        padding: 3.125rem 6rem;
      }
    }

    .card-footer {
      .btn {
        margin-bottom: 0;
      }
    }

    .teaser-card-image {
      height: auto;
      overflow: hidden;
    }
  }
}

input,
button {
  outline: none;
  box-shadow: none !important;
}

.textBlue {
  color: $dlrgColorBlue;
}

.bgBlue,
.gallery a:hover .gallery-bgBlue {
  background: $dlrgColorBlue;
}

.bgRed,
.frame-bgRed  {
  background: $dlrgColorRed;
}

.frame-bgRed {
  &,
  h1,
  h2 {
    color: $mainColorWhite;
  }
  
  a:not(.btn) {
    color: $mainColorWhite; //Sind standardmäßig blau
    text-decoration: underline; //sonst nicht vom Text unterscheidbar.

    &:hover {
      text-decoration: none;
    }
  }
}

.bgWhite {
  background: $mainColorWhite;
}

.bgBlack {
  background: $mainColorBlack;
}

.bgGrey,
.frame-bgGrey {
  background: $mainColorLightGrey;
  padding: 10px;
}

.textWhite,
.text-white {
  color: $mainColorWhite;

  a:not(.btn) {
    color: $mainColorWhite; //Sind standardmäßig blau
    text-decoration: underline; //sonst nicht vom Text unterscheidbar.

    &:hover {
      text-decoration: none;
    }
  }
}

.textYellow,
.text-dlrgGelb {
  color: $dlrgColorYellow;
}

.textRed,
.text-dlrgRot {
  color: $dlrgColorRed;
}

.textGrey {
  color: $mainColorGrey;
}

.borderBlue {
  border: 1px solid #0069b4 !important;
}

.p-inhaltsElement {
  padding: 4.375rem 1rem;
}

.ce-headline-center {
  text-align: center;
}


// Zitate
.page {
  .zitat {
    .fa {
      font-size: 2.5rem;
    }
  }
}
// ENDE Zitate


/* Bade, Eisregeln, gemeinsame Inhalte Start */
.page {
  .bildbox {
    overflow: hidden;

    picture {
      height: 100%;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      > *:last-child {
        margin-bottom: 0;
      }
    }

    .text-top,
    .text-bottom {
      position: absolute;
      padding: 1rem;
      z-index: 100;
      display: flex;
      flex-direction: column;
    }

    .text-top {
      padding-bottom: 3rem;
      justify-content: start;
      top: 0;
    }

    .text-bottom {
      padding-top: 3rem;
      justify-content: end;
      bottom: 0;
    }
  }
}
/* ENDE Bade, Eisregeln, gemeinsame Inhalte Ende */


// Icons
.navbar .fa.fa-search {
  color: $mainColorWhite;
}

.fa.fa-search {
  color: $dlrgColorBlue;
  font-size: 1.75rem;
  padding-right: 2.1875rem;
}
// ENDE Icons


// Hintergrundbilder
.page {
  .bgSuccess {
    background: url(/global/layout/2019/images/hintergrundbilder/image-1.jpg) no-repeat scroll 0 0 / cover;
    background-size: cover;
  }
}
// ENDE Hintergrundbilder Ende


// Suche
#searchRules {
  .card {
    border: solid $dlrgColorBlue;
  }
}

.tx-indexedsearch-res {
  .card {
    border: 1px solid $dlrgColorBlue;
    border-radius: .25rem;
    margin: 0.5rem;

    .card-body {
      padding: 1rem;
    }
  }
}
// ENDE Suche


// Card
.card-group {
  > .card {
    @include media-breakpoint-up(md) {
      flex: auto;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      flex: 1 0 0;
    }
  }
}
// ENDE Card


// Alert
.page .alert {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }
}
// ENDE Alert


// Custom DLRG-Alert
.alert-dlrg {
  background: $dlrgColorYellow;
  color: $dlrgColorRed;
  border-color: $dlrgColorRedHover;
}
// ENDE DLRG-Alert


// Bilder nicht verzerren, wenn 100% Höhe
.img-fluid.h-100 {
  object-fit: cover;
}
// ENDE Bilder nicht verzerren


// Video/iFrame responsive stylen
.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;

  .videoIFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
// ENDE DLRG-Alert
