@font-face {
  font-family: 'dlrg_regular';
  src: url('../font/dlrg_regular.woff2') format('woff2'),  url('../font/dlrg_regular.woff') format('woff');
  font-weight: normal;
  font-display: fallback;
  font-style: normal;
}
@font-face {
  font-family: 'dlrg_italic';
  src: url('../font/dlrg_italic.woff2') format('woff2'),  url('../font/dlrg_italic.woff') format('woff');
  font-weight: normal;
  font-display: fallback;
  font-style: normal;
}
@font-face {
  font-family: 'dlrg_bold';
  src: url('../font/dlrg_bold.woff2') format('woff2'),  url('../font/dlrg_bold.woff') format('woff');
  font-weight: normal;
  font-display: fallback;
  font-style: normal;
}

/* Farbdefinition Start */

$dlrgColorRed: #e30613;
$dlrgColorRedHover: #d40410;
$dlrgColorBlue: #0069b4;
$dlrgColorBlueHover: #005194;
$dlrgColorGreyEven: #f6f6f6;
$dlrgColorGreyOdd: #e6e6e6;
$dlrgColorYellow: #ffed00;
$dlrgColorYellowHover: #ddcb00;
$dlrgColorRequired: #008800;
$dlrgColorDisabled: #28a745;
$mainColorWhite: #ffffff;
$mainColorBlack: #000000;
$mainColorGrey:#575756;
$mainColorLightGrey:#ebebeb;
$backgroundBlue:#01679b;
$headerColorGrey:#363636;
$headerColorLightGrey:#cacaca;
$dlrgColorWhiteHover:#e2e6ea;

/* Farbdefinition Ende */


/* Fonts Start */
$dlrgFontRoman: 'dlrg_regular';
$dlrgFontItalic: 'dlrg_italic';
$dlrgFontBold: 'dlrg_bold';
/* Fonts Ende */

/* Fontawesome*/
$fa-font-path: '../fontawesome';
