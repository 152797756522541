.DreiSpalten-textBild {
  .pannelHeading {
    h4 {
      a.btn {
        color: $mainColorGrey;
        font-size: 1.5rem;
      }
    }

    .btn.focus,
    .btn:focus {
      outline: none !important;
      box-shadow: 0 0 0 0 rgba(0, 123, 255, .25) !important;
    }
  }

  .plus {
    position: relative;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;

    .line {
      width: 2px;
      height: 100%;
      left: 50%;
      top: 0;
      position: absolute;
      margin-left: -1px;
      border: 0;
    }

    .linep {
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      position: absolute;
      margin-top: -1px;
    }

    &.active .line {
      display: none;
    }
  }

  .collapseContainer {
    float: left;
    width: 100%;
    margin: 15px;

    @include media-breakpoint-up(md) {
      order: 4;
    }

    @include media-breakpoint-up(lg) {
      order: 5 !important;
    }

    .card {
      &.bgBlue {
        background: $dlrgColorBlue;
      }
    }
  }

  .box {
    @include media-breakpoint-up(md) {
      &:nth-child(1),
      &:nth-child(5) {
        order: 4;
      }

      &:nth-child(3) {
        order: 3;
      }
    }

    @include media-breakpoint-up(lg) {
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5) {
        order: 4 !important;
      }
    }

    img {
      height: auto;
      object-fit: cover;

      @include media-breakpoint-up(md) {
        max-height: 281px;
        width: 100vw;
        height: 50vh;
        object-fit: cover;
        background-position: top;
      }

      @include media-breakpoint-up(lg) {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  a.btn {
    padding: 0;
  }
}