/*-----------------------------------------------*/
/*     2-4 Spalten Inhalt strecken auf 100%      */
/*-----------------------------------------------*/
%customflexgrow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dlrg-spaltenelement {
  > .row > [class*="col"] {
    &,
    > .container:last-of-type {
      @extend %customflexgrow;

      > .frame:last-of-type:not([class*="texticon"]) {
        @extend %customflexgrow;

        .container:last-of-type {
          @extend %customflexgrow;

          .dlrg-flexbox {
            @extend %customflexgrow;
          }

          > .bildbox {
            .img-fluid {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
/*-----------------------------------------------*/
/*   ENDE 2-4 Spalten Inhalt strecken auf 100%   */
/*-----------------------------------------------*/


/*-----------------------------------------------*/
/*         padding in Spaltenelementen           */
/*-----------------------------------------------*/
.dlrg-spaltenelement {
  > .row {
    padding-left: 7.5px;
    padding-right: 7.5px;

    > div:first-of-type {
      padding-left: 7.5px !important;
    }

    > div:last-of-type {
      padding-right: 7.5px !important;
    }

    > [class*="col"] {
      padding: 7.5px;
    }
  }
}
/*-----------------------------------------------*/
/*      ENDE padding in Spaltenelementen         */
/*-----------------------------------------------*/


/*--------------------------------------------------------*/
/*    Color wenn Spaltenelement Bild als Background hat   */
/*--------------------------------------------------------*/
[class*="grid1spalte"],
[class*="grid2spalten"],
[class*="grid3spalten"],
[class*="grid4spalten"] {
  picture + .imgGradient ~ .container {
    color: $mainColorWhite;
  }
}
/*--------------------------------------------------------*/
/* ENDE Color wenn Spaltenelement Bild als Background hat */
/*--------------------------------------------------------*/