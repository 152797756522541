.seminar {
  @include teaser()
}

.seminarpool {
  form input {
    width: unset;
  }

  .fa-search {
    color: $mainColorWhite;
    font-size: 100%;
    padding-right: 0.5rem;
  }

  .gj-datepicker-bootstrap {
    background-color: #e9ecef;
  }

  .input-group {
    flex-wrap: unset;
  }

  .form-group {
    label {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

#seminarlistanker.container-fluid {
  margin-bottom: 1rem;
}

.seminar-datum,
.seminar-meldeschluss {
  min-width: min-content;
}

.Seminare {
  @include teaser();

  height: auto;
}

.seminarSingle {
  .card-body {
    .images {
      img {
        margin-bottom: 1rem;
      }
    }
  }
}

.seminarSingle,
.nativeBSCard {
  // Reenable all the native Bootstrap card styling!

  &.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
    height: $card-height;
    word-wrap: break-word;
    background-color: $card-bg;
    background-clip: border-box;
    border: $card-border-width solid $card-border-color;
    @include border-radius($card-border-radius);

    >hr {
      margin-right: 0;
      margin-left: 0;
    }

    >.list-group {
      border-top: inherit;
      border-bottom: inherit;

      &:first-child {
        border-top-width: 0;
        @include border-top-radius($card-inner-border-radius);
      }

      &:last-child {
        border-bottom-width: 0;
        @include border-bottom-radius($card-inner-border-radius);
      }
    }
  }

  .card-body {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: 1 1 auto;
    // Workaround for the image size bug in IE
    // See: https://github.com/twbs/bootstrap/pull/28855
    min-height: 1px;
    padding: $card-spacer-x;
    color: $card-color;
  }

  .card-title {
    margin-bottom: $card-spacer-y;
  }

  .card-subtitle {
    margin-top: -$card-spacer-y / 2;
    margin-bottom: 0;
  }

  .card-text:last-child {
    margin-bottom: 0;
  }

  .card-link {
    @include hover() {
      text-decoration: none;
    }

    +.card-link {
      margin-left: $card-spacer-x;
    }
  }

  //
  // Optional textual caps
  //

  .card-header {
    padding: $card-spacer-y $card-spacer-x;
    margin-bottom: 0; // Removes the default margin-bottom of <hN>
    color: $card-cap-color;
    background-color: $card-cap-bg;
    border-bottom: $card-border-width solid $card-border-color;

    &:first-child {
      @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
    }

    +.list-group {
      .list-group-item:first-child {
        border-top: 0;
      }
    }
  }

  .card-footer {
    padding: $card-spacer-y $card-spacer-x;
    color: $card-cap-color;
    background-color: $card-cap-bg;
    border-top: $card-border-width solid $card-border-color;

    &:last-child {
      @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
    }
  }
}
