.page {
  .tx_dlrgspendenshop {
    a[href="https://www.fundraisingbox.com"] {
      display: none;
    }

    p:last-of-type,
    .btn {
      margin-bottom: 0;
    }

    h3 {
      color: $dlrgColorBlue;
    }

    // soll für alle .quantity im Shop gelten
    .quantity {
      select.form-control {
        min-height: unset;
      }
    }

    @include media-breakpoint-down(sm) {
      p:last-of-type {
        margin-bottom: revert;
      }
    }

    .single-product {
      > .container {
        border: 1px solid $dlrgColorBlue;
      }
    }

    .product-details {
      .inner {
        padding: 1.5rem;
      }

      .more-link {
        margin: 0.5rem 0;

        a {
          color: $headerColorGrey;
        }
      }

      .price {
        font-size: 1.75rem;
      }
    }

    .modal-dialog {
      .modal-body {
        padding: 20px;

        @include media-breakpoint-up(sm) {
          padding: 25px;
        }

        @include media-breakpoint-up(md) {
          padding: 30px;
        }

        @include media-breakpoint-up(lg) {
          padding: 40px;
        }
      }
    }

    .imageMap {
      .imageMap-mapcol {
        position: relative;

        .dlrgBadge {
          position: absolute;
        }
      }

      .imageMap-textcol {
        .accordion .btn .accTitle {
          font-size: 1.4rem;

          // font-size erstmal wieder größer, da durch Breakpoint der Text über ganze Seite geht
          @include media-breakpoint-down(lg) {
            font-size: 1.6rem;
          }

          @include media-breakpoint-down(md) {
            font-size: 1.4rem;
          }

          @include media-breakpoint-down(sm) {
            font-size: 1.2rem;
          }

          @include media-breakpoint-down(xs) {
            font-size: 1rem;
          }
        }
      }
    }

    .basket {
      .first-row,
      .product-row {
        border-bottom: 1px solid $dlrgColorBlue;
        padding: 10px 0;

        .price {
          text-align: right;
        }
      }

      .product-row {
        .name,
        .quantity,
        .price,
        .delete {
          align-self: center;
        }
      }

      .summary {
        font-size: 1.25em;
      }
    }

    .dlrgBadge {
      width: 20%;
    }
  }
}